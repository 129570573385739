<template>
  <LayoutHome>
    <div class="bg-main">
      <div class="container">
        <div class="row">
          <nav aria-label="breadcrumb" class="w-100">
            <ol class="breadcrumb bg-main mb-0 rounded-0">
              <li
                class="breadcrumb-item text-light h6 mb-0"
                aria-current="page"
              >
                Aplikasi Pesanan
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div id="main-carousel" class="carousel slide" data-ride="carousel">
      <ul class="carousel-indicators">
        <li data-target="#main-carousel" data-slide-to="0" class="active"></li>
      </ul>
      <div
        class="carousel-inner text-center bg-dark border-bottom border-warning"
      >
        <div class="carousel-item active">
          <img
            class="img-fluid w-100"
            src="../../assets/img/ca2.jpg"
            alt="carousel"
          />
          <div class="carousel-caption">
            <h3 class="text-yellow text-shadow text-uppercase">
              Selamat Datang
            </h3>
            <p class="text-shadow h6 mb-4">Silakan login untuk memulai</p>
            <router-link
              to="/login"
              class="btn btn-light shadow text-uppercase px-5 mt-2 mb-4"
              >Login >></router-link
            >
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#main-carousel" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#main-carousel" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>

    <div class="container">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col">
          <div class="row mt-5">
            <div
              class="col-12 col-sm-6 col-lg-4 col-xl-3 offset-lg-2 offset-xl-3"
            >
              <div class="card shadow-sm mb-3">
                <div class="form-row">
                  <div class="col-md-4 mr-0 pr-0">
                    <div
                      class="
                        h1
                        text-success text-center
                        bg-light
                        h-100
                        rounded-left
                      "
                    >
                      <i class="fas fa-sign-in-alt mt-2 mt-md-4"></i>
                    </div>
                  </div>
                  <div class="col-md-8 ml-0 pl-0">
                    <div
                      class="
                        card-body
                        pl-2
                        rounded-right
                        bg-hover
                        w-100
                        text-center text-sm-left
                      "
                    >
                      <router-link
                        to="/login"
                        class="card-link stretched-link h5"
                        >Login</router-link
                      >
                      <p
                        class="
                          card-text
                          mt-2
                          d-inline-block
                          text-truncate
                          w-100
                        "
                      >
                        Masuk &amp; Kelola Pesanan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3">
              <div class="card shadow-sm mb-3">
                <div class="form-row">
                  <div class="col-md-4 mr-0 pr-0">
                    <div
                      class="
                        h1
                        text-success text-center
                        bg-light
                        h-100
                        rounded-left
                      "
                    >
                      <i class="fa fa-user-plus mt-2 mt-md-4"></i>
                    </div>
                  </div>
                  <div class="col-md-8 ml-0 pl-0">
                    <div
                      class="
                        card-body
                        pl-2
                        rounded-right
                        bg-hover
                        w-100
                        text-center text-sm-left
                      "
                    >
                      <router-link
                        to="/register"
                        class="card-link stretched-link h5"
                        >Register</router-link
                      >
                      <p
                        class="
                          card-text
                          mt-2
                          d-inline-block
                          text-truncate
                          w-100
                        "
                      >
                        Daftar &amp; Mulai Jualan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- #main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </LayoutHome>
</template>
<script>
import LayoutHome from "../../components/home/Layout.vue";
export default {
  components: {
    LayoutHome,
  },
};
</script>