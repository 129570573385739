<template>
  <header id="header" class="border-bottom">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light px-0">
        <router-link class="navbar-brand bg-transparent rounded p-0" to="/">
          <img
            src="../../assets/img/logo.png"
            alt="Logo"
            height="40"
            class="brand-logo"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav w-100">
            <li class="nav-item mr-auto active">
              <router-link class="nav-link" title="Dashboard" to="/"
                >Beranda</router-link
              >
            </li>
            <li class="nav-item pt-1 pl-lg-5">
              <router-link
                to="/login"
                class="btn btn-sm btn-outline-main px-3 mr-2"
                ><i class="fa fa-sign-in mr-2"></i> MASUK</router-link
              >
              <router-link to="/register" class="btn btn-sm btn-main px-3 mr-2"
                ><i class="fa fa-user-plus mr-2"></i> DAFTAR</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>