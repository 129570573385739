<template>
  <footer id="footer" class="bg-light">
    <div class="footer-top bg-secondary pt-5 pb-4 border-top border-success">
      <div class="container">
        <div class="row">
          <div class="col col-12 col-sm-6 col-lg-3 footer-info">
            <router-link class="navbar-brand bg-transparent p-0" to="/">
              <img
                src="../../assets/img/logo.png"
                alt="Logo"
                height="60"
                class="brand-logo"
              />
            </router-link>
            <ul class="list-inline">
              <li class="list-inline-item">
                <a
                  href="javascript:void(0);"
                  class="btn btn-outline-light rounded-circle"
                  title="Facebook"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="javascript:void(0);"
                  class="btn btn-outline-light rounded-circle"
                  title="Twitter"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="javascript:void(0);"
                  class="btn btn-outline-light rounded-circle"
                  title="Instagram"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  href="javascript:void(0);"
                  class="btn btn-outline-light rounded-circle"
                  title="Kontak Kami"
                  ><i class="fas fa-envelope"></i
                ></a>
              </li>
            </ul>
          </div>

          <div class="col col-12 col-sm-6 col-lg-3 footer-links mt-2">
            <h5 class="primary-color">Organisasi</h5>
            <ul class="list-unstyled invert-link">
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Tentang POsenan</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Karir</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Berita</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Rilis Pers</a>
              </li>
            </ul>
          </div>

          <div class="col col-12 col-sm-6 col-lg-3 footer-links mt-2">
            <h5 class="primary-color">Layanan</h5>
            <ul class="list-unstyled invert-link">
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Aturan Penggunaan</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Syarat dan Ketentuan</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Perlindungan Privasi</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Pertanyaan (FAQ)</a>
              </li>
            </ul>
          </div>

          <div class="col col-12 col-sm-6 col-lg-3 footer-links mt-2">
            <h5 class="primary-color">Menu</h5>
            <ul class="list-unstyled invert-link">
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="/">Beranda</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Keranjang Belanja</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Notifikasi</a>
              </li>
              <li>
                <i class="fa fa-caret-right mx-2 text-body"></i>
                <a href="javascript:void(0);">Rekanan</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-dark border-top">
      <div class="copyright py-3 text-center text-grey small">
        &copy; 2021 Hak Cipta POsenan
      </div>
    </div>
  </footer>
  <!-- #footer -->
</template>